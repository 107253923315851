import React, { useEffect, useState } from "react";
import "./BorrowerDetails.css";
import QRCodeGenerator from "../../components/QRCodeGenerator/QRCodeGenerator";
import Sidepopup from "../../components/Sidepopup/Sidepopup";
import axios from "axios";
import backArrow from "../../resources/images/back-arrow.png";
import Spinner from "../../components/Loading/Loading";

const BorrowerDetails = ({ loanId, setShowBorrowerDetails, fullName }) => {
  //   // State for Repay Now modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0); // Countdown in seconds
  // State for side popups
  const [isSidePopupOpen, setSidePopupOpen] = useState(false);
  const [sidePopupTitle, setSidePopupTitle] = useState("");
  const [loanData, setLoanData] = useState(null);
  const [FormattedDate, setFormattedDate] = useState("");
  const [daysOverdue, setDaysOverdue] = useState(0);
  const [dataSuccess, setDataSuccess] = useState(false);


  const handleOpenModal = () => {
    setIsModalOpen(true);
    setTimeLeft(1 * 60); // Start with 5 minutes countdown
  };

  const handleCloseModal = () => setIsModalOpen(false);



  const emiAmount = loanData?.repaymentDetails?.amount || "0";

  const handleOpenLoanDetailModal = () => {
    setSidePopupOpen(true);
    setSidePopupTitle("Loan Details");
  };

  const handleOpenBreakupModal = () => {
    setSidePopupOpen(true);
    setSidePopupTitle("Breakup");
  };

  /**
   * Fetches repayment details based on the provided loan ID and EMI amount.
   * Makes a POST request to the '/api/repayment-details/:loanId/:emiAmount' endpoint.
   *
   * On success:
   * - Checks if the API response status is 200 and contains a "success" status.
   * - Updates the success state using setDataSuccess(true).
   *
   * On failure:
   * - Sets the success state to false if the API fails or does not return success status.
   *
   * Finally:
   * - (Optional) End any loading state if implemented.
   */

  const repaymentDetails = async () => {
    try {
      // API call to fetch transaction history
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/repayment-details/${loanId}/${emiAmount}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Check if the response is successful
      if (response?.status === 200 && response?.data?.status === "success") {
        setDataSuccess(true);
      } else {
        // If the response status is not success, show error
        throw new Error("API did not return success status.");
      }
    } catch (error) {
      // If there was an error with the API request or response
      setDataSuccess(false);
    } finally {
      // setLoading(false); // End loading state
    }
  };

  /**
   * Fetches loan bill details from the API based on the provided loan ID.
   * Makes a POST request to the '/api/bill-details/:loanId' endpoint.
   *
   * On success:
   * - Sets the fetched loan data to the state using setLoanData.
   *
   * On failure:
   * - Errors are caught and can be handled if needed.
   */

  const getLoanDetailsBills = async () => {
    try {
      // GET request to fetch loan details
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/bill-details/${loanId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setLoanData(response?.data?.data); // Set fetched loan data
    } catch (error) {
      // Handle error if needed
    }
  };

  useEffect(() => {
    getLoanDetailsBills(); // Call the function to get loan details
    repaymentDetails(); // Call the function to get repayment details
  }, []); // Empty dependency array ensures this runs only on initial render

  const handleCloseSidePopup = () => setSidePopupOpen(false);

  /**
   * useEffect hook to process loan due date when loanData changes.
   * Dependencies: loanId, loanData.
   */
  useEffect(() => {
    if (loanData?.formatted_due_date) {
      // Get the formatted due date from loan data
      const dueDateString = loanData.formatted_due_date;

      // Parse the DD-MM-YYYY format into a Date object
      const [day, month, year] = dueDateString.split("-");
      const dueDate = new Date(`${year}-${month}-${day}`);

      // Format the due date to display in a user-friendly format
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dueDate);

      setFormattedDate(formattedDate);

      // Calculate days overdue
      const currentDate = new Date();
      const timeDifference = currentDate - dueDate;
      const daysOverdue = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      setDaysOverdue(daysOverdue); // Update state with the calculated overdue days

      // Log the results (optional)
    } else {
      // Handle missing due date if necessary
      setFormattedDate("No Due Date");
      setDaysOverdue(0);
    }
  }, [loanId, loanData]);

  const backToHome = () => {
    setShowBorrowerDetails(false);
  };

  return (
    <>
      {!loanData ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
          className="borrorwer-loading-container"
        >
          <Spinner height={"50px"} width={"50px"} />
        </div>
      ) : (
        <div>
          <div className="main-container">
            <div className="button-container">
              <p
                style={{
                  paddingTop: "20px",
                  width: "700px",
                  fontWeight: "border",
                  fontSize: "22px",
                  marginTop: "10px",
                }}
              >
                Borrower Details
              </p>

              <button
                style={{ display: "flex", alignItems: "center" }}
                className="back-button"
                onClick={backToHome}
              >
                <img src={backArrow} alt="Back" style={{ width: "22px", height: "18px"  , marginRight: "8px"}} />
                Back
              </button>
            </div>

            <div className="container">
              <div className="borrower-info">
                <div className="avatar">
                  {" "}
                  {loanData?.bic?.first_name?.charAt(0).toUpperCase() +
                    loanData?.bic?.last_name?.charAt(0).toUpperCase()}
                </div>
                <div className="borrower-text">
                  <p className="borrower-id">{loanData?.bic?.borrower_id}</p>
                  <p className="borrower-name">{fullName}</p>
                </div>
              </div>

              <div className="card">
                <div className="loan-details">
                  <div className="loan-amount">
                    <span className="loan-title">
                      {loanData?.product_type_name ?? "N/A"}
                    </span>
                    <h2 className="amount">
                      {dataSuccess ? 0 : emiAmount ? emiAmount : "N/a"}
                    </h2>
                  </div>

                  <button
                    onClick={(e) => {
                      if (
                        !emiAmount ||
                        Number(emiAmount) <= 0 ||
                        dataSuccess === true
                      ) {
                        e.preventDefault(); // Prevent action if the condition is met
                      } else {
                        handleOpenLoanDetailModal();
                      }
                    }}
                    className={`view-details ${
                      !emiAmount ||
                      Number(emiAmount) <= 0 ||
                      dataSuccess === true
                        ? "disabled"
                        : ""
                    }`}
                    disabled={
                      !emiAmount ||
                      Number(emiAmount) <= 0 ||
                      dataSuccess === true
                    } // Disable the button based on condition
                  >
                    View Details
                  </button>
                </div>

                <div className="due-info">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>Due Date</span>

                    <span className="due-date">
                      {!emiAmount ||
                      Number(emiAmount) <= 0 ||
                      dataSuccess === true
                        ? "No Due Date"
                        : FormattedDate}
                    </span>
                  </div>

                  <span className="overdue">
                    {!emiAmount ||
                    Number(emiAmount) <= 0 ||
                    dataSuccess === true
                      ? "0 Days Overdue"
                      : daysOverdue !== null
                      ? `${daysOverdue} Days Overdue`
                      : "No Overdue"}
                  </span>
                </div>
                <hr style={{ marginTop: "20px", borderColor: "#f3eeee" }} />

                <div className="buttons">
                  <button
                    onClick={handleOpenBreakupModal}
                    className="view-breakup"
                    disabled={
                      !emiAmount ||
                      Number(emiAmount) <= 0 ||
                      dataSuccess === true
                    }
                  >
                    View Breakup
                  </button>

                  <button
                    onClick={handleOpenModal}
                    className="repay-now"
                    disabled={
                      !emiAmount ||
                      Number(emiAmount) <= 0 ||
                      dataSuccess === true
                    }
                  >
                    Repay Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Repay Now Modal with QR Code */}
          {(emiAmount > 0 || dataSuccess == true) && (
            <QRCodeGenerator
              setShowBorrowerDetails={setShowBorrowerDetails}
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              qrCodeValue={loanData?.loan_id}
              loanData={loanData}
              loanId={loanId}
              dataSuccess={dataSuccess}
              setDataSuccess={setDataSuccess}
              fullName={fullName}
              setTimeLeft={setTimeLeft}
              timeLeft={timeLeft}
            />
          )}

          {/* Side Popup for Loan Details and Breakup */}
          <Sidepopup
            isOpen={isSidePopupOpen}
            onClose={handleCloseSidePopup}
            loanData={loanData}
            title={sidePopupTitle}
          />
        </div>
      )}
    </>
  );
};

export default BorrowerDetails;
